<template>
  <div></div>
</template>

<script>
export default {

  name: 'RedirectCuAppLinks',

  created() {
    if(this.$route.name == 'DocumentLink') {
      let botName = this.$route.params.bot
      let docId = this.$route.params.docId.replace('_', '-')
      let passageAnchor = this.$route.hash
      this.$router.push(`/${botName}/document/${docId}${passageAnchor}`)
    }
    if(this.$route.name == 'QuestionLink') {
      let botName = this.$route.params.bot
      let questionId = this.$route.params.questionId.replace('_', '-')
      this.$router.push(`/${botName}/my-apps/knowledge/question/${questionId}`)
    }
  }
}
</script>